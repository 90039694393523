<template>
  <div>
    <c-table
      ref="table"
      title="유해위해성 분류"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      :columnSetting="false"
      :isExcelDown="false"
      :isExpan="false"
      :isFullScreen="false"
      rowKey="code"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="선택" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'ghsHarmfulRiskClassPop',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '분류',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - 300);
      if (height < 500) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      this.$comm.getComboItems('HARMFUL_RISK_CLASS_CD').then(_result => {
        this.grid.data = _result;
      });
      // list setting
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
